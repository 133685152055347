/*
 * File Icon Vectors 0.8.0 (https://github.com/dmhendricks/file-icon-vectors)
 * Copyright 2018 Daniel M. Hendricks (https://www.danhendricks.com)
 * Licensed under MIT (https://github.com/dmhendricks/file-icon-vectors/blob/master/LICENSE)
 */
.fiv-background, .fiv-viv {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.fiv-size-md {
  font-size: 2.25em;
}

.fiv-size-lg {
  font-size: 3.5em;
}

.fiv-size-xl {
  font-size: 4.75em;
}

.fiv-viv {
  position: relative;
  display: inline-block;
  line-height: 1em;
  width: 0.75em;
}

.fiv-viv:before {
  content: '\00a0';
}

.fiv-viv.fiv-icon-3g2 {
  background-image: url("icons/vivid/3g2.svg");
}

.fiv-viv.fiv-icon-3ga {
  background-image: url("icons/vivid/3ga.svg");
}

.fiv-viv.fiv-icon-3gp {
  background-image: url("icons/vivid/3gp.svg");
}

.fiv-viv.fiv-icon-7z {
  background-image: url("icons/vivid/7z.svg");
}

.fiv-viv.fiv-icon-aa {
  background-image: url("icons/vivid/aa.svg");
}

.fiv-viv.fiv-icon-aac {
  background-image: url("icons/vivid/aac.svg");
}

.fiv-viv.fiv-icon-accdb {
  background-image: url("icons/vivid/accdb.svg");
}

.fiv-viv.fiv-icon-accdt {
  background-image: url("icons/vivid/accdt.svg");
}

.fiv-viv.fiv-icon-adn {
  background-image: url("icons/vivid/adn.svg");
}

.fiv-viv.fiv-icon-ai {
  background-image: url("icons/vivid/ai.svg");
}

.fiv-viv.fiv-icon-aif {
  background-image: url("icons/vivid/aif.svg");
}

.fiv-viv.fiv-icon-aifc {
  background-image: url("icons/vivid/aifc.svg");
}

.fiv-viv.fiv-icon-aiff {
  background-image: url("icons/vivid/aiff.svg");
}

.fiv-viv.fiv-icon-ait {
  background-image: url("icons/vivid/ait.svg");
}

.fiv-viv.fiv-icon-amr {
  background-image: url("icons/vivid/amr.svg");
}

.fiv-viv.fiv-icon-ani {
  background-image: url("icons/vivid/ani.svg");
}

.fiv-viv.fiv-icon-apk {
  background-image: url("icons/vivid/apk.svg");
}

.fiv-viv.fiv-icon-app {
  background-image: url("icons/vivid/app.svg");
}

.fiv-viv.fiv-icon-asax {
  background-image: url("icons/vivid/asax.svg");
}

.fiv-viv.fiv-icon-ascx {
  background-image: url("icons/vivid/ascx.svg");
}

.fiv-viv.fiv-icon-asf {
  background-image: url("icons/vivid/asf.svg");
}

.fiv-viv.fiv-icon-ash {
  background-image: url("icons/vivid/ash.svg");
}

.fiv-viv.fiv-icon-ashx {
  background-image: url("icons/vivid/ashx.svg");
}

.fiv-viv.fiv-icon-asmx {
  background-image: url("icons/vivid/asmx.svg");
}

.fiv-viv.fiv-icon-asp {
  background-image: url("icons/vivid/asp.svg");
}

.fiv-viv.fiv-icon-aspx {
  background-image: url("icons/vivid/aspx.svg");
}

.fiv-viv.fiv-icon-asx {
  background-image: url("icons/vivid/asx.svg");
}

.fiv-viv.fiv-icon-au {
  background-image: url("icons/vivid/au.svg");
}

.fiv-viv.fiv-icon-aup {
  background-image: url("icons/vivid/aup.svg");
}

.fiv-viv.fiv-icon-avi {
  background-image: url("icons/vivid/avi.svg");
}

.fiv-viv.fiv-icon-axd {
  background-image: url("icons/vivid/axd.svg");
}

.fiv-viv.fiv-icon-aze {
  background-image: url("icons/vivid/aze.svg");
}

.fiv-viv.fiv-icon-bash {
  background-image: url("icons/vivid/bash.svg");
}

.fiv-viv.fiv-icon-bat {
  background-image: url("icons/vivid/bat.svg");
}

.fiv-viv.fiv-icon-bin {
  background-image: url("icons/vivid/bin.svg");
}

.fiv-viv.fiv-icon-blank {
  background-image: url("icons/vivid/blank.svg");
}

.fiv-viv.fiv-icon-bmp {
  background-image: url("icons/vivid/bmp.svg");
}

.fiv-viv.fiv-icon-bpg {
  background-image: url("icons/vivid/bpg.svg");
}

.fiv-viv.fiv-icon-browser {
  background-image: url("icons/vivid/browser.svg");
}

.fiv-viv.fiv-icon-bz2 {
  background-image: url("icons/vivid/bz2.svg");
}

.fiv-viv.fiv-icon-c {
  background-image: url("icons/vivid/c.svg");
}

.fiv-viv.fiv-icon-cab {
  background-image: url("icons/vivid/cab.svg");
}

.fiv-viv.fiv-icon-caf {
  background-image: url("icons/vivid/caf.svg");
}

.fiv-viv.fiv-icon-cal {
  background-image: url("icons/vivid/cal.svg");
}

.fiv-viv.fiv-icon-cd {
  background-image: url("icons/vivid/cd.svg");
}

.fiv-viv.fiv-icon-cer {
  background-image: url("icons/vivid/cer.svg");
}

.fiv-viv.fiv-icon-class {
  background-image: url("icons/vivid/class.svg");
}

.fiv-viv.fiv-icon-cmd {
  background-image: url("icons/vivid/cmd.svg");
}

.fiv-viv.fiv-icon-com {
  background-image: url("icons/vivid/com.svg");
}

.fiv-viv.fiv-icon-compile {
  background-image: url("icons/vivid/compile.svg");
}

.fiv-viv.fiv-icon-config {
  background-image: url("icons/vivid/config.svg");
}

.fiv-viv.fiv-icon-cpp {
  background-image: url("icons/vivid/cpp.svg");
}

.fiv-viv.fiv-icon-cr2 {
  background-image: url("icons/vivid/cr2.svg");
}

.fiv-viv.fiv-icon-crt {
  background-image: url("icons/vivid/crt.svg");
}

.fiv-viv.fiv-icon-crypt {
  background-image: url("icons/vivid/crypt.svg");
}

.fiv-viv.fiv-icon-cs {
  background-image: url("icons/vivid/cs.svg");
}

.fiv-viv.fiv-icon-csh {
  background-image: url("icons/vivid/csh.svg");
}

.fiv-viv.fiv-icon-csproj {
  background-image: url("icons/vivid/csproj.svg");
}

.fiv-viv.fiv-icon-css {
  background-image: url("icons/vivid/css.svg");
}

.fiv-viv.fiv-icon-csv {
  background-image: url("icons/vivid/csv.svg");
}

.fiv-viv.fiv-icon-cue {
  background-image: url("icons/vivid/cue.svg");
}

.fiv-viv.fiv-icon-dat {
  background-image: url("icons/vivid/dat.svg");
}

.fiv-viv.fiv-icon-db {
  background-image: url("icons/vivid/db.svg");
}

.fiv-viv.fiv-icon-dbf {
  background-image: url("icons/vivid/dbf.svg");
}

.fiv-viv.fiv-icon-deb {
  background-image: url("icons/vivid/deb.svg");
}

.fiv-viv.fiv-icon-dgn {
  background-image: url("icons/vivid/dgn.svg");
}

.fiv-viv.fiv-icon-dll {
  background-image: url("icons/vivid/dll.svg");
}

.fiv-viv.fiv-icon-dmg {
  background-image: url("icons/vivid/dmg.svg");
}

.fiv-viv.fiv-icon-dng {
  background-image: url("icons/vivid/dng.svg");
}

.fiv-viv.fiv-icon-doc {
  background-image: url("icons/vivid/doc.svg");
}

.fiv-viv.fiv-icon-docb {
  background-image: url("icons/vivid/docb.svg");
}

.fiv-viv.fiv-icon-docm {
  background-image: url("icons/vivid/docm.svg");
}

.fiv-viv.fiv-icon-docx {
  background-image: url("icons/vivid/docx.svg");
}

.fiv-viv.fiv-icon-dot {
  background-image: url("icons/vivid/dot.svg");
}

.fiv-viv.fiv-icon-dotm {
  background-image: url("icons/vivid/dotm.svg");
}

.fiv-viv.fiv-icon-dotx {
  background-image: url("icons/vivid/dotx.svg");
}

.fiv-viv.fiv-icon-dpj {
  background-image: url("icons/vivid/dpj.svg");
}

.fiv-viv.fiv-icon-dtd {
  background-image: url("icons/vivid/dtd.svg");
}

.fiv-viv.fiv-icon-dwg {
  background-image: url("icons/vivid/dwg.svg");
}

.fiv-viv.fiv-icon-dxf {
  background-image: url("icons/vivid/dxf.svg");
}

.fiv-viv.fiv-icon-eot {
  background-image: url("icons/vivid/eot.svg");
}

.fiv-viv.fiv-icon-eps {
  background-image: url("icons/vivid/eps.svg");
}

.fiv-viv.fiv-icon-epub {
  background-image: url("icons/vivid/epub.svg");
}

.fiv-viv.fiv-icon-exe {
  background-image: url("icons/vivid/exe.svg");
}

.fiv-viv.fiv-icon-f4v {
  background-image: url("icons/vivid/f4v.svg");
}

.fiv-viv.fiv-icon-fax {
  background-image: url("icons/vivid/fax.svg");
}

.fiv-viv.fiv-icon-fb2 {
  background-image: url("icons/vivid/fb2.svg");
}

.fiv-viv.fiv-icon-fla {
  background-image: url("icons/vivid/fla.svg");
}

.fiv-viv.fiv-icon-flac {
  background-image: url("icons/vivid/flac.svg");
}

.fiv-viv.fiv-icon-flv {
  background-image: url("icons/vivid/flv.svg");
}

.fiv-viv.fiv-icon-folder {
  background-image: url("icons/vivid/folder.svg");
}

.fiv-viv.fiv-icon-gadget {
  background-image: url("icons/vivid/gadget.svg");
}

.fiv-viv.fiv-icon-gem {
  background-image: url("icons/vivid/gem.svg");
}

.fiv-viv.fiv-icon-gif {
  background-image: url("icons/vivid/gif.svg");
}

.fiv-viv.fiv-icon-gitignore {
  background-image: url("icons/vivid/gitignore.svg");
}

.fiv-viv.fiv-icon-gpg {
  background-image: url("icons/vivid/gpg.svg");
}

.fiv-viv.fiv-icon-gz {
  background-image: url("icons/vivid/gz.svg");
}

.fiv-viv.fiv-icon-h {
  background-image: url("icons/vivid/h.svg");
}

.fiv-viv.fiv-icon-htm {
  background-image: url("icons/vivid/htm.svg");
}

.fiv-viv.fiv-icon-html {
  background-image: url("icons/vivid/html.svg");
}

.fiv-viv.fiv-icon-ibooks {
  background-image: url("icons/vivid/ibooks.svg");
}

.fiv-viv.fiv-icon-ico {
  background-image: url("icons/vivid/ico.svg");
}

.fiv-viv.fiv-icon-ics {
  background-image: url("icons/vivid/ics.svg");
}

.fiv-viv.fiv-icon-idx {
  background-image: url("icons/vivid/idx.svg");
}

.fiv-viv.fiv-icon-iff {
  background-image: url("icons/vivid/iff.svg");
}

.fiv-viv.fiv-icon-image {
  background-image: url("icons/vivid/image.svg");
}

.fiv-viv.fiv-icon-img {
  background-image: url("icons/vivid/img.svg");
}

.fiv-viv.fiv-icon-indd {
  background-image: url("icons/vivid/indd.svg");
}

.fiv-viv.fiv-icon-inf {
  background-image: url("icons/vivid/inf.svg");
}

.fiv-viv.fiv-icon-ini {
  background-image: url("icons/vivid/ini.svg");
}

.fiv-viv.fiv-icon-iso {
  background-image: url("icons/vivid/iso.svg");
}

.fiv-viv.fiv-icon-jar {
  background-image: url("icons/vivid/jar.svg");
}

.fiv-viv.fiv-icon-java {
  background-image: url("icons/vivid/java.svg");
}

.fiv-viv.fiv-icon-jpe {
  background-image: url("icons/vivid/jpe.svg");
}

.fiv-viv.fiv-icon-jpeg {
  background-image: url("icons/vivid/jpeg.svg");
}

.fiv-viv.fiv-icon-jpg {
  background-image: url("icons/vivid/jpg.svg");
}

.fiv-viv.fiv-icon-js {
  background-image: url("icons/vivid/js.svg");
}

.fiv-viv.fiv-icon-json {
  background-image: url("icons/vivid/json.svg");
}

.fiv-viv.fiv-icon-jsp {
  background-image: url("icons/vivid/jsp.svg");
}

.fiv-viv.fiv-icon-key {
  background-image: url("icons/vivid/key.svg");
}

.fiv-viv.fiv-icon-kf8 {
  background-image: url("icons/vivid/kf8.svg");
}

.fiv-viv.fiv-icon-ksh {
  background-image: url("icons/vivid/ksh.svg");
}

.fiv-viv.fiv-icon-less {
  background-image: url("icons/vivid/less.svg");
}

.fiv-viv.fiv-icon-licx {
  background-image: url("icons/vivid/licx.svg");
}

.fiv-viv.fiv-icon-lit {
  background-image: url("icons/vivid/lit.svg");
}

.fiv-viv.fiv-icon-log {
  background-image: url("icons/vivid/log.svg");
}

.fiv-viv.fiv-icon-lua {
  background-image: url("icons/vivid/lua.svg");
}

.fiv-viv.fiv-icon-m2v {
  background-image: url("icons/vivid/m2v.svg");
}

.fiv-viv.fiv-icon-m3u {
  background-image: url("icons/vivid/m3u.svg");
}

.fiv-viv.fiv-icon-m3u8 {
  background-image: url("icons/vivid/m3u8.svg");
}

.fiv-viv.fiv-icon-m4a {
  background-image: url("icons/vivid/m4a.svg");
}

.fiv-viv.fiv-icon-m4r {
  background-image: url("icons/vivid/m4r.svg");
}

.fiv-viv.fiv-icon-m4v {
  background-image: url("icons/vivid/m4v.svg");
}

.fiv-viv.fiv-icon-master {
  background-image: url("icons/vivid/master.svg");
}

.fiv-viv.fiv-icon-md {
  background-image: url("icons/vivid/md.svg");
}

.fiv-viv.fiv-icon-mdb {
  background-image: url("icons/vivid/mdb.svg");
}

.fiv-viv.fiv-icon-mdf {
  background-image: url("icons/vivid/mdf.svg");
}

.fiv-viv.fiv-icon-mid {
  background-image: url("icons/vivid/mid.svg");
}

.fiv-viv.fiv-icon-midi {
  background-image: url("icons/vivid/midi.svg");
}

.fiv-viv.fiv-icon-mkv {
  background-image: url("icons/vivid/mkv.svg");
}

.fiv-viv.fiv-icon-mobi {
  background-image: url("icons/vivid/mobi.svg");
}

.fiv-viv.fiv-icon-mov {
  background-image: url("icons/vivid/mov.svg");
}

.fiv-viv.fiv-icon-mp2 {
  background-image: url("icons/vivid/mp2.svg");
}

.fiv-viv.fiv-icon-mp3 {
  background-image: url("icons/vivid/mp3.svg");
}

.fiv-viv.fiv-icon-mp4 {
  background-image: url("icons/vivid/mp4.svg");
}

.fiv-viv.fiv-icon-mpa {
  background-image: url("icons/vivid/mpa.svg");
}

.fiv-viv.fiv-icon-mpd {
  background-image: url("icons/vivid/mpd.svg");
}

.fiv-viv.fiv-icon-mpe {
  background-image: url("icons/vivid/mpe.svg");
}

.fiv-viv.fiv-icon-mpeg {
  background-image: url("icons/vivid/mpeg.svg");
}

.fiv-viv.fiv-icon-mpg {
  background-image: url("icons/vivid/mpg.svg");
}

.fiv-viv.fiv-icon-mpga {
  background-image: url("icons/vivid/mpga.svg");
}

.fiv-viv.fiv-icon-mpp {
  background-image: url("icons/vivid/mpp.svg");
}

.fiv-viv.fiv-icon-mpt {
  background-image: url("icons/vivid/mpt.svg");
}

.fiv-viv.fiv-icon-msi {
  background-image: url("icons/vivid/msi.svg");
}

.fiv-viv.fiv-icon-msu {
  background-image: url("icons/vivid/msu.svg");
}

.fiv-viv.fiv-icon-nef {
  background-image: url("icons/vivid/nef.svg");
}

.fiv-viv.fiv-icon-nes {
  background-image: url("icons/vivid/nes.svg");
}

.fiv-viv.fiv-icon-odb {
  background-image: url("icons/vivid/odb.svg");
}

.fiv-viv.fiv-icon-odt {
  background-image: url("icons/vivid/odt.svg");
}

.fiv-viv.fiv-icon-ogg {
  background-image: url("icons/vivid/ogg.svg");
}

.fiv-viv.fiv-icon-ogv {
  background-image: url("icons/vivid/ogv.svg");
}

.fiv-viv.fiv-icon-ost {
  background-image: url("icons/vivid/ost.svg");
}

.fiv-viv.fiv-icon-otf {
  background-image: url("icons/vivid/otf.svg");
}

.fiv-viv.fiv-icon-ott {
  background-image: url("icons/vivid/ott.svg");
}

.fiv-viv.fiv-icon-ovf {
  background-image: url("icons/vivid/ovf.svg");
}

.fiv-viv.fiv-icon-p12 {
  background-image: url("icons/vivid/p12.svg");
}

.fiv-viv.fiv-icon-p7b {
  background-image: url("icons/vivid/p7b.svg");
}

.fiv-viv.fiv-icon-pages {
  background-image: url("icons/vivid/pages.svg");
}

.fiv-viv.fiv-icon-part {
  background-image: url("icons/vivid/part.svg");
}

.fiv-viv.fiv-icon-pcd {
  background-image: url("icons/vivid/pcd.svg");
}

.fiv-viv.fiv-icon-pdb {
  background-image: url("icons/vivid/pdb.svg");
}

.fiv-viv.fiv-icon-pdf {
  background-image: url("icons/vivid/pdf.svg");
}

.fiv-viv.fiv-icon-pem {
  background-image: url("icons/vivid/pem.svg");
}

.fiv-viv.fiv-icon-pfx {
  background-image: url("icons/vivid/pfx.svg");
}

.fiv-viv.fiv-icon-pgp {
  background-image: url("icons/vivid/pgp.svg");
}

.fiv-viv.fiv-icon-php {
  background-image: url("icons/vivid/php.svg");
}

.fiv-viv.fiv-icon-png {
  background-image: url("icons/vivid/png.svg");
}

.fiv-viv.fiv-icon-po {
  background-image: url("icons/vivid/po.svg");
}

.fiv-viv.fiv-icon-pot {
  background-image: url("icons/vivid/pot.svg");
}

.fiv-viv.fiv-icon-potx {
  background-image: url("icons/vivid/potx.svg");
}

.fiv-viv.fiv-icon-pps {
  background-image: url("icons/vivid/pps.svg");
}

.fiv-viv.fiv-icon-ppsx {
  background-image: url("icons/vivid/ppsx.svg");
}

.fiv-viv.fiv-icon-ppt {
  background-image: url("icons/vivid/ppt.svg");
}

.fiv-viv.fiv-icon-pptm {
  background-image: url("icons/vivid/pptm.svg");
}

.fiv-viv.fiv-icon-pptx {
  background-image: url("icons/vivid/pptx.svg");
}

.fiv-viv.fiv-icon-prop {
  background-image: url("icons/vivid/prop.svg");
}

.fiv-viv.fiv-icon-ps {
  background-image: url("icons/vivid/ps.svg");
}

.fiv-viv.fiv-icon-psd {
  background-image: url("icons/vivid/psd.svg");
}

.fiv-viv.fiv-icon-psp {
  background-image: url("icons/vivid/psp.svg");
}

.fiv-viv.fiv-icon-pst {
  background-image: url("icons/vivid/pst.svg");
}

.fiv-viv.fiv-icon-pub {
  background-image: url("icons/vivid/pub.svg");
}

.fiv-viv.fiv-icon-py {
  background-image: url("icons/vivid/py.svg");
}

.fiv-viv.fiv-icon-qt {
  background-image: url("icons/vivid/qt.svg");
}

.fiv-viv.fiv-icon-ra {
  background-image: url("icons/vivid/ra.svg");
}

.fiv-viv.fiv-icon-ram {
  background-image: url("icons/vivid/ram.svg");
}

.fiv-viv.fiv-icon-rar {
  background-image: url("icons/vivid/rar.svg");
}

.fiv-viv.fiv-icon-raw {
  background-image: url("icons/vivid/raw.svg");
}

.fiv-viv.fiv-icon-rb {
  background-image: url("icons/vivid/rb.svg");
}

.fiv-viv.fiv-icon-rdf {
  background-image: url("icons/vivid/rdf.svg");
}

.fiv-viv.fiv-icon-resx {
  background-image: url("icons/vivid/resx.svg");
}

.fiv-viv.fiv-icon-rm {
  background-image: url("icons/vivid/rm.svg");
}

.fiv-viv.fiv-icon-rpm {
  background-image: url("icons/vivid/rpm.svg");
}

.fiv-viv.fiv-icon-rtf {
  background-image: url("icons/vivid/rtf.svg");
}

.fiv-viv.fiv-icon-rub {
  background-image: url("icons/vivid/rub.svg");
}

.fiv-viv.fiv-icon-sass {
  background-image: url("icons/vivid/sass.svg");
}

.fiv-viv.fiv-icon-scss {
  background-image: url("icons/vivid/scss.svg");
}

.fiv-viv.fiv-icon-sdf {
  background-image: url("icons/vivid/sdf.svg");
}

.fiv-viv.fiv-icon-sh {
  background-image: url("icons/vivid/sh.svg");
}

.fiv-viv.fiv-icon-sitemap {
  background-image: url("icons/vivid/sitemap.svg");
}

.fiv-viv.fiv-icon-skin {
  background-image: url("icons/vivid/skin.svg");
}

.fiv-viv.fiv-icon-sldm {
  background-image: url("icons/vivid/sldm.svg");
}

.fiv-viv.fiv-icon-sldx {
  background-image: url("icons/vivid/sldx.svg");
}

.fiv-viv.fiv-icon-sln {
  background-image: url("icons/vivid/sln.svg");
}

.fiv-viv.fiv-icon-sql {
  background-image: url("icons/vivid/sql.svg");
}

.fiv-viv.fiv-icon-step {
  background-image: url("icons/vivid/step.svg");
}

.fiv-viv.fiv-icon-stl {
  background-image: url("icons/vivid/stl.svg");
}

.fiv-viv.fiv-icon-svg {
  background-image: url("icons/vivid/svg.svg");
}

.fiv-viv.fiv-icon-swd {
  background-image: url("icons/vivid/swd.svg");
}

.fiv-viv.fiv-icon-swf {
  background-image: url("icons/vivid/swf.svg");
}

.fiv-viv.fiv-icon-swift {
  background-image: url("icons/vivid/swift.svg");
}

.fiv-viv.fiv-icon-sys {
  background-image: url("icons/vivid/sys.svg");
}

.fiv-viv.fiv-icon-tar {
  background-image: url("icons/vivid/tar.svg");
}

.fiv-viv.fiv-icon-tcsh {
  background-image: url("icons/vivid/tcsh.svg");
}

.fiv-viv.fiv-icon-tex {
  background-image: url("icons/vivid/tex.svg");
}

.fiv-viv.fiv-icon-tga {
  background-image: url("icons/vivid/tga.svg");
}

.fiv-viv.fiv-icon-tgz {
  background-image: url("icons/vivid/tgz.svg");
}

.fiv-viv.fiv-icon-tif {
  background-image: url("icons/vivid/tif.svg");
}

.fiv-viv.fiv-icon-tiff {
  background-image: url("icons/vivid/tiff.svg");
}

.fiv-viv.fiv-icon-torrent {
  background-image: url("icons/vivid/torrent.svg");
}

.fiv-viv.fiv-icon-ts {
  background-image: url("icons/vivid/ts.svg");
}

.fiv-viv.fiv-icon-tsv {
  background-image: url("icons/vivid/tsv.svg");
}

.fiv-viv.fiv-icon-ttf {
  background-image: url("icons/vivid/ttf.svg");
}

.fiv-viv.fiv-icon-txt {
  background-image: url("icons/vivid/txt.svg");
}

.fiv-viv.fiv-icon-udf {
  background-image: url("icons/vivid/udf.svg");
}

.fiv-viv.fiv-icon-vb {
  background-image: url("icons/vivid/vb.svg");
}

.fiv-viv.fiv-icon-vbproj {
  background-image: url("icons/vivid/vbproj.svg");
}

.fiv-viv.fiv-icon-vcd {
  background-image: url("icons/vivid/vcd.svg");
}

.fiv-viv.fiv-icon-vcs {
  background-image: url("icons/vivid/vcs.svg");
}

.fiv-viv.fiv-icon-vdi {
  background-image: url("icons/vivid/vdi.svg");
}

.fiv-viv.fiv-icon-vdx {
  background-image: url("icons/vivid/vdx.svg");
}

.fiv-viv.fiv-icon-vmdk {
  background-image: url("icons/vivid/vmdk.svg");
}

.fiv-viv.fiv-icon-vob {
  background-image: url("icons/vivid/vob.svg");
}

.fiv-viv.fiv-icon-vsd {
  background-image: url("icons/vivid/vsd.svg");
}

.fiv-viv.fiv-icon-vss {
  background-image: url("icons/vivid/vss.svg");
}

.fiv-viv.fiv-icon-vst {
  background-image: url("icons/vivid/vst.svg");
}

.fiv-viv.fiv-icon-vsx {
  background-image: url("icons/vivid/vsx.svg");
}

.fiv-viv.fiv-icon-vtx {
  background-image: url("icons/vivid/vtx.svg");
}

.fiv-viv.fiv-icon-war {
  background-image: url("icons/vivid/war.svg");
}

.fiv-viv.fiv-icon-wav {
  background-image: url("icons/vivid/wav.svg");
}

.fiv-viv.fiv-icon-wbk {
  background-image: url("icons/vivid/wbk.svg");
}

.fiv-viv.fiv-icon-webinfo {
  background-image: url("icons/vivid/webinfo.svg");
}

.fiv-viv.fiv-icon-webm {
  background-image: url("icons/vivid/webm.svg");
}

.fiv-viv.fiv-icon-webp {
  background-image: url("icons/vivid/webp.svg");
}

.fiv-viv.fiv-icon-wma {
  background-image: url("icons/vivid/wma.svg");
}

.fiv-viv.fiv-icon-wmf {
  background-image: url("icons/vivid/wmf.svg");
}

.fiv-viv.fiv-icon-wmv {
  background-image: url("icons/vivid/wmv.svg");
}

.fiv-viv.fiv-icon-woff {
  background-image: url("icons/vivid/woff.svg");
}

.fiv-viv.fiv-icon-woff2 {
  background-image: url("icons/vivid/woff2.svg");
}

.fiv-viv.fiv-icon-wsf {
  background-image: url("icons/vivid/wsf.svg");
}

.fiv-viv.fiv-icon-xaml {
  background-image: url("icons/vivid/xaml.svg");
}

.fiv-viv.fiv-icon-xcf {
  background-image: url("icons/vivid/xcf.svg");
}

.fiv-viv.fiv-icon-xlm {
  background-image: url("icons/vivid/xlm.svg");
}

.fiv-viv.fiv-icon-xls {
  background-image: url("icons/vivid/xls.svg");
}

.fiv-viv.fiv-icon-xlsm {
  background-image: url("icons/vivid/xlsm.svg");
}

.fiv-viv.fiv-icon-xlsx {
  background-image: url("icons/vivid/xlsx.svg");
}

.fiv-viv.fiv-icon-xlt {
  background-image: url("icons/vivid/xlt.svg");
}

.fiv-viv.fiv-icon-xltm {
  background-image: url("icons/vivid/xltm.svg");
}

.fiv-viv.fiv-icon-xltx {
  background-image: url("icons/vivid/xltx.svg");
}

.fiv-viv.fiv-icon-xml {
  background-image: url("icons/vivid/xml.svg");
}

.fiv-viv.fiv-icon-xpi {
  background-image: url("icons/vivid/xpi.svg");
}

.fiv-viv.fiv-icon-xps {
  background-image: url("icons/vivid/xps.svg");
}

.fiv-viv.fiv-icon-xrb {
  background-image: url("icons/vivid/xrb.svg");
}

.fiv-viv.fiv-icon-xspf {
  background-image: url("icons/vivid/xspf.svg");
}

.fiv-viv.fiv-icon-xz {
  background-image: url("icons/vivid/xz.svg");
}

.fiv-viv.fiv-icon-yml {
  background-image: url("icons/vivid/yml.svg");
}

.fiv-viv.fiv-icon-z {
  background-image: url("icons/vivid/z.svg");
}

.fiv-viv.fiv-icon-zip {
  background-image: url("icons/vivid/zip.svg");
}

.fiv-viv.fiv-icon-zsh {
  background-image: url("icons/vivid/zsh.svg");
}
